import Link from 'next/link'
import { ReactNode } from 'react'

import Lines from '@/components/backgrounds/Lines'

export default function WavesLayout({ children }: { children: ReactNode }): JSX.Element {
	return (
		<div className="min-h-screen flex items-center bg-gray-100">
			<div className="mx-auto w-full overflow-x-hidden py-8">
				<div>
					<Link href="/">
						<a tabIndex={-1}>
							<svg className="w-44 mx-auto" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176 42"><path d="M41.73 16.64h17.8c2.63 0 3.95.83 3.95 2.5v3.16c0 1.64-1.32 2.45-3.92 2.45H46.93v4.99h-5.2v-13.1zm14.9 6.74c.63 0 1.04-.08 1.29-.22.22-.14.35-.42.35-.8v-3.32c0-.4-.1-.67-.35-.81a2.66 2.66 0 00-1.29-.23H46.9v5.36h9.73v.02zM71.18 29.74h-2.63l10.96-13.1h4.7l10.17 13.1h-5.86L80.2 18.98l-9.01 10.76zM103.7 29.74c-2.63 0-3.95-.81-3.95-2.46v-8.13c0-1.68 1.32-2.51 3.95-2.51h14.57v1.4H106.6c-.63 0-1.04.08-1.28.21-.22.14-.36.42-.36.81v8.22c0 .42.1.7.36.84.21.14.65.22 1.28.22h11.67v1.43H103.7v-.03zM124.6 16.64h18.2v1.4h-13.02v4.26h13.01v1.39h-13v4.63h13.06v1.42h-18.22v-13.1h-.03z" fill="#363940" /><path d="M84.38 25.28c-8.68 0-15.72-1.81-15.72-4.04 0-1.45 2.96-2.7 7.4-3.43-6.36.61-10.88 2.12-10.88 3.9 0 2.31 7.64 4.18 17.04 4.18 7.4 0 13.7-1.14 16.05-2.76-2.65 1.28-7.86 2.15-13.89 2.15z" fill="#363940" /><path d="M101.84 3.23c-45.1 0-81.65 9.4-81.65 20.99 0 7.5 15.34 14.07 38.36 17.78C25.48 38.85 2 31 2 21.74 2 9.73 41.67 0 90.6 0c38.41 0 71.13 6 83.4 14.4-13.73-6.65-40.88-11.17-72.16-11.17z" fill="#8DC63F" /></svg>
						</a>
					</Link>
				</div>
				<div className="max-w-screen px-6 mt-9">
					<div className="container mx-auto relative max-w-screen-xl">
						<Lines className="hidden sm:block" />

						<div className="relative">
							{ children }
						</div>
					</div>
				</div>
				<div className="flex justify-center mt-6">
					<div className="flex space-x-1.5 sm:space-x-3">
						<Link href="/terms-and-conditions"><a className="block underline text-gray-800 text-sm font-semibold md:text-base">Terms &amp; Conditions</a></Link>
						<span>|</span>
						<Link href="/privacy-policy"><a className="block underline text-gray-800 text-sm font-semibold md:text-base">Privacy Policy</a></Link>
						<span className="hidden sm:block">|</span>
						<Link href="/contact-us"><a className="hidden underline text-gray-800 text-sm font-semibold sm:block md:text-base">Contact Us</a></Link>
					</div>
				</div>
				<div className="flex justify-center mt-2 sm:hidden">
					<Link href="/contact-us"><a className="block underline text-gray-800 text-sm font-semibold md:text-base">Contact Us</a></Link>
				</div>
			</div>
		</div>
	)
}
