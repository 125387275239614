import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'

import LoadingIcon from '@/components/icons/Loading'
import WavesLayout from '@/components/layouts/Waves'
import { useAuthentication } from '@/components/providers/AuthenticationProvider'

export default function AuthenticationLayout({ children }: { children: ReactNode }): JSX.Element {
	const { email } = useAuthentication()
	const router = useRouter()
	const previousURL = Array.isArray(router.query.back) ? router.query.back[0] : router.query.back


	useEffect(() => {
		if (typeof email === 'string') {
			const timeout = setTimeout(() => void router.replace(previousURL ?? '/account'), 500)

			return () => clearTimeout(timeout)
		}
	}, [email, router, previousURL])

	let content = children
	if (typeof email === 'string') {
		content = (
			<div className="py-32 flex justify-center items-center">
				<LoadingIcon />
			</div>
		)
	}

	return (
		<WavesLayout>
			{content}
		</WavesLayout>
	)
}
