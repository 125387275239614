import { HTMLAttributes } from 'react'

export default function Lines(attributes: HTMLAttributes<HTMLDivElement>): JSX.Element {
	return (
		<div {...attributes}>
			<div className="absolute top-0 right-0 pt-6 sm:-mr-32 md:-mr-60 lg:-mr-32 xl:-mr-48">
				<svg className="h-40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 160">
					<g strokeMiterlimit="10" strokeLinecap="round">
						<path className="text-teal-400" stroke="currentColor" d="M280.875 85.622c-36.438 3.522-73.452 7.646-110.542 12.499" strokeWidth="12" />
						<path className="text-green-400" stroke="currentColor" d="M216.7 49.294C148.469 56.964 79 66.885 11.046 79.709" strokeWidth="16" />
						<path className="text-green-500" stroke="currentColor" d="M333.579 48.294c-81.116 7.297-164.517 16.76-247.891 28.827" strokeWidth="16" style={{ mixBlendMode: 'multiply' }} />
					</g>
				</svg>
			</div>

			<div className="absolute bottom-0 left-0 pb-6 sm:-ml-32 md:-ml-64 lg:-ml-32 xl:-ml-48">
				<svg className="h-40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 160">
					<g strokeMiterlimit="10" strokeLinecap="round">
						<path className="text-teal-400" stroke="currentColor" d="M70.927 71.34c36.521-2.514 73.635-5.614 110.845-9.44" strokeWidth="12" />
						<path className="text-green-400" stroke="currentColor" d="M134.373 109.414c68.418-5.782 138.134-13.78 206.417-24.72" strokeWidth="16" />
						<path className="text-green-500" stroke="currentColor" d="M17.211 107.197c81.287-5.052 164.917-12.208 248.594-21.965" strokeWidth="16" style={{ mixBlendMode: 'multiply' }} />
					</g>
				</svg>
			</div>
		</div>
	)
}
