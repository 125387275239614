import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormEventHandler, useState } from 'react'

import AuthenticationLayout from '@/components/layouts/Authentication'
import { useAuthentication } from '@/components/providers/AuthenticationProvider'
import { Status, useStatus } from '@/hooks/useStatus'

export default function Login(): JSX.Element {
	const { login } = useAuthentication()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [status, setStatus] = useStatus(Status.Idle)
	const router = useRouter()
	const previousURL = Array.isArray(router.query.back) ? router.query.back[0] : router.query.back

	const handleLogin: FormEventHandler = event => {
		event.preventDefault()
		setStatus(Status.Loading)

		login(email, password).then(() => {
			void router.push(previousURL ?? '/account')
		}).catch(() => {
			setStatus(Status.Error)
		})
	}

	return (
		<form className="mx-auto w-full max-w-xs text-gray-800 space-y-6 bg-white shadow-card rounded-lg pt-3 px-3 pb-6 md:max-w-md md:p-8 md:space-y-9 xl:max-w-xl" onSubmit={handleLogin}>
			<Head>
				<title key="page-title">Login | Pace International</title>
			</Head>

			<h1 className="text-xl font-bold text-center md:text-3xl">Sign in to your account</h1>

			<div>
				<div className="">
					<label className="text-sm md:text-base" htmlFor="email">Email</label>
				</div>
				<input className="mt-1.5 px-2 py-1.5 text-sm shadow-input appearance-none w-full h-8 rounded md:h-11 md:rounded-lg" id="email" type="email" required value={email} onChange={event => setEmail(event.target.value)} />
			</div>

			<div>
				<div className="flex justify-between">
					<label className="text-sm md:text-base" htmlFor="password">Password</label>
					<Link href="/forgot-password"><a className="underline text-green-400 text-sm font-semibold md:text-base">Forgot Password?</a></Link>
				</div>
				<input className="mt-1.5 px-2 py-1.5 text-sm shadow-input appearance-none w-full h-8 rounded md:h-11 md:rounded-lg" id="password" type="password" required value={password} onChange={event => setPassword(event.target.value)} />
			</div>
			{ status === Status.Error && (
				<div>
					<p className="text-center text-red-700 font-bold -mt-6 -mb-4">Invalid Credentials</p>
				</div>
			)}

			<button className="py-2 w-full bg-gradient-to-b from-green-400 via-green-500 to-green-500 text-white rounded-full uppercase text-base font-extrabold leading-5 md:h-11 md:text-lg" type="submit">{status === Status.Loading ? 'Signing In' : 'Sign In'}</button>


			<div className="text-center space-y-3">
				<p className="text-sm md:text-base">Don't have an account yet? <Link href={`/register${previousURL === undefined ? '' : `?back=${previousURL}`}`}><a className="underline text-green-400 text-sm font-semibold md:text-base">Sign Up</a></Link></p>

				<Link href="/"><a className="inline-block underline text-gray-800 text-sm font-semibold md:text-base">Return to home</a></Link>
			</div>
		</form>
	)
}

Login.Layout = AuthenticationLayout
